body {
  font-family: monospace;
}

p {
  margin: 0;
  padding: 0;
}

.box {
  border: 1px solid;
  margin: 0.5em;
  padding: 0.5em;
}

.entity-over {
  color: red;
}
